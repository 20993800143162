import { service } from "../_helpers";

export const auditorService = {
  getGlobalAuditors,
  getByMatterID,
  add,
  update,
  addGlobalAuditorToMatter,
  removeGlobalAuditorFromMatter,
  _delete,
};

function getGlobalAuditors() {
  return service.fetch.get(`/api/auditor/getGlobalAuditors`);
}

function getByMatterID(id) {
  return service.fetch.post(`/api/auditor/getByMatterId`, JSON.stringify(id));
}

function add(auditor) {
  return service.fetch.post(`/api/auditor/add`, auditor);
}

function update(auditor) {
  return service.fetch.post(`/api/auditor/update`, auditor);
}

function addGlobalAuditorToMatter(auditorID, matterID) {
  return service.fetch.post(
    `/api/auditor/addGlobalAuditorToMatter`,
    JSON.stringify({ auditorID, matterID }),
  );
}

function removeGlobalAuditorFromMatter(auditorID, matterID) {
  debugger;
  return service.fetch.post(
    `/api/auditor/removeGlobalAuditorFromMatter`,
    JSON.stringify({ auditorID, matterID }),
  );
}

function _delete(id) {
  return service.fetch.delete(`/api/auditor/delete`, JSON.stringify(id));
}
