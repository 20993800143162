const Liquidation = { id: 1, name: "Insolvencies" };
const EstateLate = { id: 2, name: "Deceased Estates" };
const Curatorship = { id: 3, name: "Curatorship" };
const FlowConsult = { id: 4, name: "Flow Consult" };
const Trust = { id: 5, name: "Trust" };

const List = [Liquidation, EstateLate, Curatorship, FlowConsult, Trust];

export const SystemTypeEnum = {
  Liquidation,
  EstateLate,
  Curatorship,
  FlowConsult,
  Trust,
  List,
};
