//import config from 'config';
//import REACT_APP_SERVER_URL from '../config';
import { service, serviceBuild, tokenStore } from "../_helpers";

export const userService = {
  getInternalToken,
  login,
  refreshToken,
  token,
  logout,
  add,
  update,
  updateTermsAndConditions,
  getNewChangesFlag,
  updateSystemType,
  getList,
  getById,
  getRoles,
  getByEmail,
  getUserName,
  getUserRoles,
  getUserIDByMatterID,
  getEmailNotifications,
  resetPassword,
  changePassword,
  forgotPassword,
  checkAccess,
  _delete,
  authenticate2FA,
};

function getInternalToken(keycloakToken) {
  var config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + keycloakToken,
    },
  };
  return service.fetch.postRaw(
    `/account/GetInternalToken`,
    keycloakToken,
    config,
  );
}

function authenticate2FA(code, provider, ReturnURL) {
  let model = { code, provider, ReturnURL };
  return service.fetch.post(`/account/authenticate2FA`, model);
}

function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };
  //var xx = process.env.REACT_APP_API_URL;
  return (
    fetch(`/account/authenticate`, requestOptions)
      //return fetch(`${REACT_APP_SERVER_URL}/account/authenticate`, requestOptions)
      //return fetch(`http://localhost:80/account/authenticate`, requestOptions)
      .then(service.handleResponse, service.handleError)
      .then((user) => {
        if (user && user.token) {
          localStorage.setItem("user", JSON.stringify(user));

          tokenStore.saveUserToken(user.token);
          //tokenStore.saveRefreshToken(user.refreshToken ? user.refreshToken : '');
        }
        return user;
      })
  );
}

function token() {
  return service.fetch.get(`/account/token`);
}

function getNewChangesFlag() {
  return service.fetch.get(`/api/userApi/getNewChangesFlag`);
}

function checkAccess() {
  return service.fetch.get(`/api/userApi/checkAccess`);
}

async function refreshToken(userToken, refreshToken) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + refreshToken,
    },
    body: JSON.stringify({ userToken, refreshToken }),
  };

  return fetch("/account/refreshToken", requestOptions).then((response) => {
    return new Promise(async (resolve, reject) => {
      if (response.ok) {
        var contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          response.json().then((json) => {
            resolve(json);
          });
        } else {
          resolve();
        }
      } else {
        var message = "Refresh Token Error";
        if (response.statusText && response.statusText !== "") {
          message = response.statusText;
        }
        reject(message);
      }
    });
  });
}

function resetPassword(userDetails) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: { 'Content-Type': 'application/json' },
  //  body: JSON.stringify(userDetails)
  //};

  //var result = fetch(`/account/resetpassword`, requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/account/resetpassword`, userDetails);
}

function logout() {
  if (localStorage.getItem("user")) {
    localStorage.removeItem("user");
    tokenStore.clearTokens();
    window.location.reload(true);
  }
}

function add(userDetails) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(userDetails)
  //};

  //var result = fetch(`/api/userApi/add`, requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/userApi/add`, userDetails);
}

function update(userDetails) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(userDetails)
  //};

  //var result = fetch(`/api/userApi/update`, requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/userApi/update`, userDetails);
}

function updateTermsAndConditions(userID) {
  return service.fetch
    .post(`/api/userApi/updateTermsAndConditions`, JSON.stringify(userID))
    .then((user) => {
      if (user && user.token) {
        localStorage.setItem("user", JSON.stringify(user));
        tokenStore.saveUserToken(user.token);
        //tokenStore.saveRefreshToken(user.refreshToken ? user.refreshToken : '');
      }
      return user;
    });
}

function updateSystemType(systemTypeID, userID) {
  var obj = {
    systemTypeID,
    userID,
  };
  return service.fetch
    .post(`/api/userApi/updateSystemType`, obj)
    .then((user) => {
      if (user && user.token) {
        localStorage.setItem("user", JSON.stringify(user));

        tokenStore.saveUserToken(user.token);
        //tokenStore.saveRefreshToken(user.refreshToken ? user.refreshToken : '');
      }
      return user;
    });
}

function getList(skip = 0, take = 0, intermediaryID = 0, searchString = "") {
  debugger;
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var users = fetch(serviceBuild.buildUrl(`/api/userApi/getlist`, { skip, take, intermediaryID, searchString }), requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //return users;
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/userApi/getlist`, {
      skip,
      take,
      intermediaryID,
      searchString,
    }),
  );
}

function getById(id) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var user = fetch(serviceBuild.buildUrl(`/api/userApi/getbyid`,{ id }), requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //return user;
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/userApi/getbyid`, { id }),
  );
}

function getByEmail(email) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var user = fetch(serviceBuild.buildUrl(`/api/userApi/getbyemail`, { email }), requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //return user;
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/userApi/getbyemail`, { email }),
  );
}

function _delete(id) {
  //const requestOptions = {
  //  method: 'DELETE',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var result = fetch(`/api/userApi/delete`, requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //return result;
  return service.fetch.delete(`/api/userApi/delete`, JSON.stringify(id));
}

function getUserName() {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var username = fetch(`/api/userApi/getusername`, requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //return username;
  return service.fetch.get(`/api/userApi/getusername`);
}

function getRoles() {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var roles = fetch(`/api/userApi/getroles`, requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //return roles;
  return service.fetch.get(`/api/userApi/getroles`);
}

function getUserRoles(id) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var userroles = fetch(`/api/userApi/getuserroles`, requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //return userroles;
  return service.fetch.post(`/api/userApi/getuserroles`, JSON.stringify(id));
}

function getUserIDByMatterID(matterID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/userApi/getuseridbymatterid`, { matterID }),
  );
}

function getEmailNotifications() {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var emailnotifications = fetch(`/api/userApi/getemailnotifications`, requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //return emailnotifications;
  return service.fetch.get(`/api/userApi/getemailnotifications`);
}

function changePassword(userDetails) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(userDetails)
  //};

  //var result = fetch(`/account/changepassword`, requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/account/changepassword`, userDetails);
}

function forgotPassword(userDetails) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: { 'Content-Type': 'application/json' },
  //  body: JSON.stringify(userDetails)
  //};
  //var result = fetch(`/api/userApi/forgotpassword`, requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/userApi/forgotpassword`, userDetails);
}

//function getall() {
//  const requestOptions = {
//    method: 'POST',
//    headers: { 'Content-Type': 'application/json' }
//  };

//  return fetch(`/account/getall`, requestOptions);

//}

//function handleResponse(response) {
//    var xx = 0;
//    return response.text().then(text => {
//        const data = text && JSON.parse(text);
//        if (!response.ok) {
//            if (response.status === 401) {
//                //auto logout
//                logout();
//                window.location.reload(true);
//            }
//            const error = (data && data.message) || response.statusText;
//            return Promise.reject(error);
//        }
//        return data
//    });
//}

//function handleResponse(response) {
//  return new Promise((resolve, reject) => {
//    if (response.ok) {
//      // return json if it was returned in the response
//      var contentType = response.headers.get("content-type");
//      if (contentType && contentType.includes("application/json")) {
//        response.json().then(json => {
//          resolve(json);
//        });
//      } else {
//        resolve();
//      }
//    } else {
//      // return error message from response body
//      var errorMessage = response.statusText;
//      response.text().then(text => {
//        if (text !== "") { errorMessage = text;}
//        NotificationManager.error(errorMessage, 'Error' , 30000);
//        reject(text);
//      });
//    }
//  });
//}

//function handleError(error) {
//  NotificationManager.error(error && error.message, 'Error', 30000);
//  return Promise.reject(error && error.message);
//}
