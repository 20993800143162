import { incomeConstants } from '../_constants';
import { incomeService } from '../_services';

export const incomeActions = {
  getList,
  getListByMatterID,
  getByID,
  add,
  update,
  _delete,
};

function getList(skip, take, searchString) {

  return dispatch => {
    dispatch(request());
    return incomeService.getList(skip, take, searchString)
      .then(
        incomes => dispatch(success(incomes)),
      );
  };

  function request() { return { type: incomeConstants.GETLIST_REQUEST }; }
  function success(incomes) { return { type: incomeConstants.GETLIST_SUCCESS, incomes }; }
  function failure(error) { return { type: incomeConstants.GETLIST_FAILURE, error }; }
}


function getListByMatterID(id, searchString, skip, take) {
  return dispatch => {
    dispatch(request());

    return incomeService.getListByMatterID(id, searchString, skip, take)
      .then(
        incomes => dispatch(success(incomes)),
      );
  };

  function request() { return { type: incomeConstants.GETLISTBYMATTERID_REQUEST }; }
  function success(incomes) { return { type: incomeConstants.GETLISTBYMATTERID_SUCCESS, incomes }; }
  function failure(error) { return { type: incomeConstants.GETLISTBYMATTERID_FAILURE, error }; }
}

function getByID(id) {
  return dispatch => {
    dispatch(request());

    return incomeService.getByID(id)
      .then(
        income => dispatch(success(income)),
      );
  };

  function request() { return { type: incomeConstants.GETBYID_REQUEST } }
  function success(income) { return { type: incomeConstants.GETBYID_SUCCESS, income } }
  function failure(error) { return { type: incomeConstants.GETBYID_FAILURE, error } }
}

function add(income) {
  return dispatch => {
    dispatch(request());

    return incomeService.add(income)
      .then(
        dispatch(success(income)),
      );
  };

  function request() { return { type: incomeConstants.ADD_REQUEST } }
  function success(income) { return { type: incomeConstants.ADD_SUCCESS, income } }
  function failure(error) { return { type: incomeConstants.ADD_FAILURE, error } }
}

function update(income) {
  return dispatch => {
    dispatch(request());

    return incomeService.update(income)
      .then(
        dispatch(success()),
      );
  };

  function request() { return { type: incomeConstants.UPDATE_REQUEST } }
  function success() { return { type: incomeConstants.UPDATE_SUCCESS } }
  function failure(error) { return { type: incomeConstants.UPDATE_FAILURE, error } }
}

function _delete(id) {
  return dispatch => {
    dispatch(request());
    return incomeService._delete(id)
      .then(
        dispatch(success()),
      );
  };

  function request() { return { type: incomeConstants.DELETE_REQUEST } }
  function success() { return { type: incomeConstants.DELETE_SUCCESS } }
  function failure(error) { return { type: incomeConstants.DELETE_FAILURE, error } }
}



