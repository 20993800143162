import { matterConstants } from "../_constants";

export function matters(state = {}, action) {
  switch (action.type) {
    case matterConstants.GETLIST_REQUEST:
      return {
        loading: true,
      };
    case matterConstants.GETLIST_SUCCESS:
      return action.matters;

    case matterConstants.SETMATTERID_SUCCESS:
      return {
        matterID: action.matterId,
      };

    case matterConstants.GETLIST_FAILURE:
      return {
        error: action.error,
      };

    case matterConstants.GETBYID_REQUEST:
      return {
        loading: true,
      };
    case matterConstants.GETBYID_SUCCESS:
      return action.matter;
    case matterConstants.GETBYID_FAILURE:
      return {
        error: action.error,
      };

    case matterConstants.GETMATTERCOMMENTBYMATTERID_REQUEST:
      return {
        loading: true,
      };
    case matterConstants.GETMATTERCOMMENTBYMATTERID_SUCCESS:
      return action.matterComments;
    case matterConstants.GETMATTERCOMMENTBYMATTERID_FAILURE:
      return {
        error: action.error,
      };

    case matterConstants.ADD_REQUEST:
      return {
        loading: true,
      };
    case matterConstants.ADD_SUCCESS:
      return action.type;
    case matterConstants.ADD_FAILURE:
      return {
        error: action.error,
      };

    case matterConstants.ADDCOMMENT_REQUEST:
      return {
        loading: true,
      };
    case matterConstants.ADDCOMMENT_SUCCESS:
      return action.matterComment;
    case matterConstants.ADDCOMMENT_FAILURE:
      return {
        error: action.error,
      };

    case matterConstants.UPDATE_REQUEST:
      return {
        loading: true,
      };
    case matterConstants.UPDATE_SUCCESS:
      return action.type;
    case matterConstants.UPDATE_FAILURE:
      return {
        error: action.error,
      };

    case matterConstants.UPDATESTATUS_REQUEST:
      return {
        loading: true,
      };
    case matterConstants.UPDATESTATUS_SUCCESS:
      return action.type;
    case matterConstants.UPDATESTATUS_FAILURE:
      return {
        error: action.error,
      };

    case matterConstants.UPDATEWATCHLISTSTATUS_REQUEST:
      return {
        loading: true,
      };
    case matterConstants.UPDATEWATCHLISTSTATUS_SUCCESS:
      return action.type;
    case matterConstants.UPDATEWATCHLISTSTATUS_FAILURE:
      return {
        error: action.error,
      };

    case matterConstants.DELETE_REQUEST:
      return {
        loading: true,
      };
    case matterConstants.DELETE_SUCCESS:
      return action.type;
    case matterConstants.DELETE_FAILURE:
      return {
        error: action.error,
      };

    case matterConstants.GETMATTERSBYINTERMEDIARY_REQUEST:
      return {
        loading: true,
      };
    case matterConstants.GETMATTERSBYINTERMEDIARY_SUCCESS:
      return action.matters;
    case matterConstants.GETMATTERSBYINTERMEDIARY_FAILURE:
      return {
        error: action.error,
      };

    case matterConstants.GETACTIVENUMBEROFACCOUNT_REQUEST:
      return {
        loading: true,
      };
    case matterConstants.GETACTIVENUMBEROFACCOUNT_SUCCESS:
      return action.matters;
    case matterConstants.GETACTIVENUMBEROFACCOUNT_FAILURE:
      return {
        error: action.error,
      };

    case matterConstants.GETACTIVEACCOUNTASSETVALUE_REQUEST:
      return {
        loading: true,
      };
    case matterConstants.GETACTIVEACCOUNTASSETVALUE_SUCCESS:
      return action.matters;
    case matterConstants.GETACTIVEACCOUNTASSETVALUE_FAILURE:
      return {
        error: action.error,
      };

    case matterConstants.GETPROJECTEDFEES_REQUEST:
      return {
        loading: true,
      };
    case matterConstants.GETPROJECTEDFEES_SUCCESS:
      return action.matters;
    case matterConstants.GETPROJECTEDFEES_FAILURE:
      return {
        error: action.error,
      };

    case matterConstants.UPLOADEDDEATHNOTICEAUDIT_REQUEST:
      return {
        loading: true,
      };
    case matterConstants.UPLOADEDDEATHNOTICEAUDIT_SUCCESS:
      return action.type;
    case matterConstants.UPLOADEDDEATHNOTICEAUDIT_FAILURE:
      return {
        error: action.error,
      };

    case matterConstants.UPLOADEDDEATHCERTIFICATEAUDIT_REQUEST:
      return {
        loading: true,
      };
    case matterConstants.UPLOADEDDEATHCERTIFICATEAUDIT_SUCCESS:
      return action.type;
    case matterConstants.UPLOADEDDEATHCERTIFICATEAUDIT_FAILURE:
      return {
        error: action.error,
      };

    case matterConstants.UPLOADEDORIGINALWILLAUDIT_REQUEST:
      return {
        loading: true,
      };
    case matterConstants.UPLOADEDORIGINALWILLAUDIT_SUCCESS:
      return action.type;
    case matterConstants.UPLOADEDORIGINALWILLAUDIT_FAILURE:
      return {
        error: action.error,
      };

    case matterConstants.UPLOADEDACCEPTANCEOFTRUSTASEXECUTORAUDIT_REQUEST:
      return {
        loading: true,
      };
    case matterConstants.UPLOADEDACCEPTANCEOFTRUSTASEXECUTORAUDIT_SUCCESS:
      return action.type;
    case matterConstants.UPLOADEDACCEPTANCEOFTRUSTASEXECUTORAUDIT_FAILURE:
      return {
        error: action.error,
      };

    case matterConstants.UPLOADEDNOMINATIONTOACTASEXECUTORAUDIT_REQUEST:
      return {
        loading: true,
      };
    case matterConstants.UPLOADEDNOMINATIONTOACTASEXECUTORAUDIT_SUCCESS:
      return action.type;
    case matterConstants.UPLOADEDNOMINATIONTOACTASEXECUTORAUDIT_FAILURE:
      return {
        error: action.error,
      };

    case matterConstants.UPLOADEDCERTIFIEDEXECUTORIDAUDIT_REQUEST:
      return {
        loading: true,
      };
    case matterConstants.UPLOADEDCERTIFIEDEXECUTORIDAUDIT_SUCCESS:
      return action.type;
    case matterConstants.UPLOADEDCERTIFIEDEXECUTORIDAUDIT_FAILURE:
      return {
        error: action.error,
      };

    case matterConstants.UPLOADEDACCEPTANCEOFMASTERSDIRECTIONAUDIT_REQUEST:
      return {
        loading: true,
      };
    case matterConstants.UPLOADEDACCEPTANCEOFMASTERSDIRECTIONAUDIT_SUCCESS:
      return action.type;
    case matterConstants.UPLOADEDACCEPTANCEOFMASTERSDIRECTIONAUDIT_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}
