const Weekly = { Name: "Weekly", ID: 1 };
const Monthly = { Name: "Monthly", ID: 2 };
const Never = { Name: "Never", ID: 3 };
const BiWeekly = { Name: "Fortnightly (1st and 14th)", ID: 4 };

export const BatchFrequencyEnum = {
  Weekly,
  BiWeekly,
  Monthly,
  Never,
};
