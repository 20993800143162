//import config from 'config';
//import config from '../webpack.config';
import { service, serviceBuild } from "../_helpers";

export const personOfInterestService = {
  _delete,
  unlinkPersonOfInterestFromMatter,
  add,
  addDeceasedChild,
  addDeceasedSpouse,
  addedLiabilityAudit,
  addSARSInfo,
  deleteDeceasedChild,
  deleteDeceasedSpouse,
  deleteSars,
  getByMatterID,
  getDeceasedChildByInsolventID,
  getDeceasedSpouseByInsolventID,
  getDirectorMembersByCompanyID,
  getList,
  getGlobalFounders,
  getSarsList,
  isDeceasedOrdinaryResident,
  update,
  updateResident,
  updateDeceasedChild,
  updateDeceasedSpouse,
  updatedLiabilityAudit,
  updateSARSInfo,
  uploadedAntenuptialContractAudit,
  uploadedDeceasedIDAudit,
  uploadedDeclarationOfMarriageAudit,
  uploadedDivorceOrderOrSettlementAgreementAudit,
  uploadedIncomeTaxRefAudit,
  uploadedMarriageCertificateAudit,
};

function updateResident(model) {
  return service.fetch.post(`/api/insolvent/updateResident`, model);
}
function getList(skip, take) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var insolvents = fetch(`/api/insolvent/getlist`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return insolvents;
  return service.fetch.get(`/api/insolvent/getlist`);
}

function getGlobalFounders() {
  return service.fetch.get(`/api/insolvent/getGlobalFounders`);
}

function getDirectorMembersByCompanyID(id) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var insolvents = fetch(serviceBuild.buildUrl(`/api/insolvent/getDirectorMembersByCompanyID`, { id }), requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return insolvents;
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/insolvent/getDirectorMembersByCompanyID`, {
      id,
    }),
  );
}

function getDeceasedSpouseByInsolventID(id) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var insolvents = fetch(serviceBuild.buildUrl(`/api/insolvent/getDirectorMembersByCompanyID`, { id }), requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return insolvents;
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/insolvent/getDeceasedSpouseByInsolventID`, {
      id,
    }),
  );
}

function getSarsList(id) {
  return service.fetch.post(`/api/insolvent/getSarsList`, id);
}

function deleteSars(id) {
  return service.fetch.delete(`/api/insolvent/deleteSars`, JSON.stringify(id));
}

function updateSARSInfo(sars) {
  return service.fetch.post(`/api/insolvent/updateSARSInfo`, sars);
}

function addSARSInfo(sars) {
  return service.fetch.post(`/api/insolvent/addSARSInfo`, sars);
}

function addDeceasedSpouse(deceasedSpouse) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(insolvent)
  //};
  //var result = fetch(`/api/insolvent/add`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/insolvent/addDeceasedSpouse`, deceasedSpouse);
}

function updateDeceasedSpouse(deceasedSpouse) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(insolvent)
  //};
  //var result = fetch(`/api/insolvent/add`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(
    `/api/insolvent/updateDeceasedSpouse`,
    deceasedSpouse,
  );
}

function deleteDeceasedSpouse(id) {
  //const requestOptions = {
  //  method: 'DELETE',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var result = fetch(`/api/insolvent/_delete`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;

  return service.fetch.delete(
    `/api/insolvent/deleteDeceasedSpouse`,
    JSON.stringify(id),
  );
}

function getDeceasedChildByInsolventID(id) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var insolvents = fetch(serviceBuild.buildUrl(`/api/insolvent/getDirectorMembersByCompanyID`, { id }), requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return insolvents;
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/insolvent/getDeceasedChildByInsolventID`, {
      id,
    }),
  );
}

function getByMatterID(id, skip, take) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var insolvents = fetch(`/api/insolvent/getbymatterid`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return insolvents;
  return service.fetch.post(`/api/insolvent/getbymatterid`, JSON.stringify(id));
}

function isDeceasedOrdinaryResident(matterID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/insolvent/isDeceasedOrdinaryResident`, {
      matterID,
    }),
  );
}

function add(insolvent) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(insolvent)
  //};
  //var result = fetch(`/api/insolvent/add`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/insolvent/add`, insolvent);
}

function addDeceasedChild(deceasedChild) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(insolvent)
  //};
  //var result = fetch(`/api/insolvent/add`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(`/api/insolvent/addDeceasedChild`, deceasedChild);
}

function updateDeceasedChild(deceasedChild) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(insolvent)
  //};
  //var result = fetch(`/api/insolvent/add`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.post(
    `/api/insolvent/updateDeceasedChild`,
    deceasedChild,
  );
}

function update(insolvent) {
  return service.fetch.post(`/api/insolvent/update`, insolvent);
}

function deleteDeceasedChild(id) {
  //const requestOptions = {
  //  method: 'DELETE',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var result = fetch(`/api/insolvent/_delete`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;

  return service.fetch.delete(
    `/api/insolvent/deleteDeceasedChild`,
    JSON.stringify(id),
  );
}

function _delete(id) {
  //const requestOptions = {
  //  method: 'DELETE',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var result = fetch(`/api/insolvent/_delete`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;

  return service.fetch.delete(`/api/insolvent/_delete`, JSON.stringify(id));
}

function unlinkPersonOfInterestFromMatter(personID, matterID) {
  return service.fetch.post(
    `/api/insolvent/unlinkPersonOfInterestFromMatter`,
    JSON.stringify({ personID, matterID }),
  );
}

function uploadedDeceasedIDAudit(insolvent) {
  return service.fetch.post(`/api/insolvent/uploaddeceasedidaudit`, insolvent);
}

function uploadedMarriageCertificateAudit(insolvent) {
  return service.fetch.post(
    `/api/insolvent/uploadmarriagecertificateaudit`,
    insolvent,
  );
}

function uploadedDivorceOrderOrSettlementAgreementAudit(insolvent) {
  return service.fetch.post(
    `/api/insolvent/uploaddivorceorderorsettleaudit`,
    insolvent,
  );
}

function uploadedDeclarationOfMarriageAudit(insolvent) {
  return service.fetch.post(
    `/api/insolvent/uploaddeclarationofmarriageaudit`,
    insolvent,
  );
}

function uploadedIncomeTaxRefAudit(insolvent) {
  return service.fetch.post(
    `/api/insolvent/uploadincometaxrefaudit`,
    insolvent,
  );
}

function uploadedAntenuptialContractAudit(insolvent) {
  return service.fetch.post(
    `/api/insolvent/uploadantenuptialcontractaudit`,
    insolvent,
  );
}

function addedLiabilityAudit(insolvent) {
  return service.fetch.post(`/api/insolvent/deceasedaddedaudit`, insolvent);
}

function updatedLiabilityAudit(insolvent) {
  return service.fetch.post(`/api/insolvent/deceasedupdatedaudit`, insolvent);
}

//function handleResponse(response) {
//  return new Promise((resolve, reject) => {
//    if (response.ok) {
//      // return json if it was returned in the response
//      var contentType = response.headers.get("content-type");
//      if (contentType && contentType.includes("application/json")) {
//        response.json().then(json => {
//          resolve(json);
//        });
//      } else {
//        resolve();
//      }
//    } else {
//      // return error message from response body
//      response.text().then(text => {
//        reject(text);
//      });
//    }
//  });
//}

//function handleError(error) {
//  return Promise.reject(error && error.message);
//}
