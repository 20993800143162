import React, { Component } from "react";
import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};
const DEFAULT_YEAR = 2023;

class DefaultFooter extends Component {
  constructor() {
    super();
    this.state = {
      currentYear: DEFAULT_YEAR,
    };
  }

  setCurrentYear = () => {
    this.setState({ currentYear: new Date().getFullYear() });
  };

  componentDidMount = () => {
    this.setCurrentYear();
  };

  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <span>
          <a href="https://fennecflow.com/">Fennec Flow</a> &copy;
          {this.state.currentYear}
          <span id="year"></span>
        </span>
        <span className="ml-auto">
          Powered by <a href="https://www.fennecgroup.co.za/">Fennec Group</a>
        </span>
      </React.Fragment>
    );
  }
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;
